.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999;
}
.loader .loader-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader .loader-area .css-1mxr1bq,
.loader .loader-area .css-8vx899,
.loader .loader-area .css-anak8p {
  width: 80px;
  height: 80px;
}

.loader .loader-area  .loading-text {
  font-size: 25px;
  font-style: normal;
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
}

.loader .loader-area  .loading-text p{
  font-size: 25px;
  font-style: normal;
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
}


.loader .loader-area .css-1i5m00m .css-m5syh7,
.loader .loader-area .css-1i5m00m .css-8q43r4 {
  background-color: #d82e35;
  height: 70px;
  width: 70px;
}
