hr {
  width: 100%;
}


.Toastify__toast--success {
  background: #42d82e;
}
body {
  font-family: "Montserrat", sans-serif !important; 
  background: #1c2228;
}

/* ========== Custom Scroll Styles =========== */
/* 
width
::-webkit-scrollbar {
  width: 7px;
}

Track
::-webkit-scrollbar-track {
  background: #27293d;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #333645;
}

Handle on hover
::-webkit-scrollbar-thumb:hover {
  background: #333645;
} */


/* ========== Custom MainContainer Styles =========== */

.main-container {
  color: white;
  backdrop-filter: blur(4px);
}

.main-container-head {
  display: flex;
  justify-content: space-between;
}

.main-container-heading {
  color: #000000;
  font-size: 18px;
  font-weight: 550;
  margin-top: 10px;
}

/* ========== Custom Table Styles =========== */

.table .rt-tbody .rt-td {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ========== Custom Slider Styles =========== */

.slider {
  padding: 30px 40px;
  width: 1175px;
}

/*========== Custom Styles View Data  ============= */

.view-data .view-data-main-title {
  font-weight: 500;
  font-size: 26px;
  padding-bottom: 5px;
}

.view-data .view-data-body .view-data-row {
  background-color: #eaeded  ;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
}

.view-data .view-data-body .view-data-row .view-data-title {
  font-weight: 600;
}

.view-data .view-data-body .view-data-sub-title {
  font-size: 20px;
  font-weight: 600;
}

.view-data .view-data-footer {
  display: flex;
  justify-content: center;
}


/*========== Custom Styles Edit-Add Slider ============= */

.edit-add {
  border-radius: 20px;
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
}

.edit-add .edit-add-title {
padding: 15px;
font-size: 26px;
font-weight: 500;
text-align: left;
}
.edit-add .edit-add-title h1{
  margin-bottom: 0px;
}

.edit-add .edit-add-sub-title {
  font-size: 20px;
  font-weight: 500;
  text-align: start;
  }

.edit-add .edit-add-body .edit-img {
  width: 120px; 
  height: 120px; 
  border-radius: 50%;
  background: 'gray';
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiFormLabel-root.Mui-focused {
  color: #d82e35;
}

.edit-add .edit-add-body .form.edit-add-field .form-input-field
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #6c757d;
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid #d82e35;
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiOutlinedInput-notchedOutline {
  border: 1px solid #6c757d;
}

.MuiAutocomplete-option {
  color: #000000;
}

.edit-add .edit-add-body .edit-add-buttons {
display: flex;
justify-content: center;
width: 100%;
}

/* ========== Custom Button Styles =========== */


.add-btn {
  color: #ffffff !important;
  padding: 5px 15px !important;
  border-radius: 10px !important;
  border: 1px solid #333645 !important;
  background-color: #333645 !important;
}

.add-btn:hover {
  color: #ffffff !important;
  background-color: #d82e35 !important;
  border: 1px solid #d82e35 !important;
}

.view-btn {
  min-width: 45% !important;
  color: white !important;
  border-radius: 6px !important;
  border: 1px solid #333645 !important;

}

.view-btn:hover {
  color: #ffffff !important;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}

.edit-btn {
  min-width: 45% !important;
  color: #d82e35 !important;
  border-radius: 10px !important;
  border: 1px solid #333645 !important;
}

.edit-btn:hover {
  color: #ffffff !important;
  background-color: #d82e35 !important;
}

.delete-btn {
  min-width: 30% !important;
  color: #d82e35 !important;
  border-radius: 10px !important;
  border: 1px solid #d82e35 !important;
}

.delete-btn:hover {
  color: #ffffff !important;
  background-color: #d82e35 !important;
}

.cancel-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #d82e35  !important;
}

.submit-btn {
    border-radius: 10px !important;
    color: #ffffff !important;
    background: #d82e35  !important;
}

.confirm-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #d82e35  !important;
}

Button:focus {
    outline: 0px !important; 
}

/* ========== Custom AppLoader Styles =========== */

.loader-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  color: #d82e35;
}

.blue-loader {
  color: #d82e35;
}

/* ========== Main Modal Styles =========== */

.main-modal {
  max-width: 500px !important;
  top: 5%;
}

.main-modal .modal-header,
.modal-footer {
  border: none !important;
}

.main-modal .modal-content {
  background-color: #d82e35 !important;
  border-radius: 30px !important;
}

.main-modal .modal-content .modal-header {
  display: flex;                   
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.main-modal .modal-content .modal-header .close {
  color: #fff !important;
}

.main-modal .modal-content .modal-header .modal-logo {
  display: flex;
  justify-content: center;
}

.main-modal .modal-content .modal-header .modal-logo img {
  width: 85%;
  padding: 10px;
  margin: 30px 0px;
  border-radius: 20px;
  background-color: #0f0f0f;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.main-modal .modal-content .modal-body {
  padding: 0px;
}


/* ========== Custom Styles Message Modal =========== */

.message-modal {
  max-width: 1050px !important;
  top: 0% !important;
}

.modal.show .modal-dialog.message-modal {
  transform: translate(0, 5%);
}

.message-modal .message-modal-title p {
  color: #fff !important;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.message-modal .message-modal-line hr {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.message-modal .modal-body .message-text {
  display: flex;
  justify-content: center;
  padding: 25px;
  margin: 25px 0px 80px;
  border-radius: 15px;
  background: rgba(10, 10, 10, 0.7);
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.message-modal .modal-body .message-text h3 {
  margin-bottom: 0px;
}


/*========== Responsive ============= */
@media(max-width:991px){
  .logOutBtnSidebar{
    position: relative !important;
    bottom: unset !important;
  }
  .bgPanel,
  .bgPanel2 {
    display: none;
  }
  .sidebar{
    padding-top: 60px !important;
  }
  .edit-add{
    padding: 20px;
  }
  .main-panel .content {
    padding-left: 0px;
    padding-right: 0px;
}
.sidebar, .off-canvas-sidebar{
  min-width: 0px !important;
}
.navbar .navbar-brand{
  margin-left: 0px !important;
}
.logoHeader img {
  margin-right: 5px !important;
}
.logoHeader div h3 {
  font-size: 13px !important;
}
.logoHeader div p{
  font-size: 10px !important;
}
.detailsAdmin h4{
  font-size: 13px !important;
}
.detailsAdmin p{
  font-size: 10px !important;
}
.navbar .photo img{
  width: 30px !important;
  height: 32px !important;
}
.detailsAdmin {
  margin-right: 5px !important;
}
.navbar{
  padding: 5px 0px 5px 0px !important;
}
.nav-bar .navbar-collapse{
  flex-basis: fit-content;

}
}
@media only screen and (max-width: 650px){

  .slider {
      padding: 30px;
      width: 500px;
  }

  .slider .edit-add {
      padding: 20px 10px;
  }

  .slider .edit-add .slider-body .edit-add-buttons {
      padding: 30px;
  }
}

@media only screen and (max-width: 425px){
  .slider {
      padding: 20px;
      width: 400px;
  }
}

@media only screen and (max-width: 375px){
  .slider {
      padding: 10px;
      width: 325px;
  }
}
.detailsAdmin h4{
  display: flex;
  align-items: center;
}
.detailsAdmin span{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%) !important;
  display: inline-block;
  margin-left: 10px;
}
.detailsAdmin h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1.2em;
  color: #1D2833;
  margin-bottom: 0px;
  text-align: right;
}
.detailsAdmin p {
  color: #999fc2 !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.1em;
}
.logoHeader img {
  margin-right: 10px;
  margin-left: 68px;
}
.edit-add h1{
  color: #1D2833;
  font-size: 24px;
}
.logoHeader div h3{
  color: white !important;
  font-weight: 800;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}
.logoHeader div p{
font-size: 12px;
color: #999FC2  !important;
opacity: 0.7;
font-weight: 500;
margin-bottom: 0px;
}
.SideBrNContent{
  display: flex;
  align-items: flex-start;
}
.sidebar-wrapper ul li.active a{
  background: #1c2228 !important;
  border-radius: 0;
}

.sidebar-wrapper ul li.active a:before{
    position: absolute;
    content: '';
    width: 45px;
    height: 46px;
    border-right: 0;
    border-top: 0;
    left: 0;
    top: 5px;
    border-radius: 10px;
    transform: rotate(45deg);
    left: -23px;
    background: #1c2228;
    z-index: -1;
}

.sidebar-wrapper .nav li a p.linkNameSide {
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  text-transform: capitalize;
}
.sidebar-wrapper .nav li a img{
  margin-right: 10px;
}
.InputFieldCustom {
  height: 90px;
  width: 100%;
  border-bottom-width: 2px;
  border-color: rgba(208, 211, 221, 1);
  border-left-width: 2px;
  border-right-width: 2px;
  border-style: solid;
  border-top-width: 2px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 10px;
}
.form-input-field .MuiInputBase-root {
  height: 90px;
  border-bottom-width: 2px;
  border-color: transparent;
  border-left-width: 2px;
  border-right-width: 2px;
  border-style: solid;
  border-top-width: 2px;
  width: 100%;
  border-radius: 10px;
  background-color: #fff !important;
  opacity: 1;
}
.form-input-field .MuiFilledInput-underline:before{
  border-bottom: none;
}
.form-input-field .MuiFilledInput-underline:after{
  border-bottom: none;
}
.form-input-field .MuiFilledInput-root:hover{
  border-color: #55cbc6 !important;
}
.form-input-field .MuiFilledInput-underline:hover:before{
  border-color: transparent;
}
.form-input-field .MuiInputLabel-filled.MuiInputLabel-shrink label{
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Inter Tight", sans-serif !important;
}
.form-input-field .MuiFormLabel-root.Mui-focused{
  color:#3552FA !important;
}
.form-input-field input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #3d3c45 inset !important;
  border-radius: 0px;
}
.form-input-field .labelColorValidator{
  color: #CDD0Da;
}
.form-input-field .MuiInputBase-input{
  color: white;
}
.edit-add-buttons .submit-btn{
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%) !important;
  height: 56px;
  width: 100%;
  font-size: 18px;
}
.mint-new-NFT .edit-add{
max-width:800px;
margin: 0 auto;
}
.table .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{
  color:white;
}
.table .rt-tbody .rt-td{
  color:white;
}
.table .rt-table{
  background: gray;
}
.bgPanel {
  background-image: url(/images/bgTop-svg.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0.63;
  aspect-ratio: 1.55731 / 1;
  flex: 0 0 auto;
  mix-blend-mode: overlay;
  overflow: visible;
  position: absolute;
  width: 590px;
  height: 477px;
  top: 0px;
  right: 0px;
}
.bgPanel2 {
  background-image: url(/images/bgBottomCropped.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  aspect-ratio: 1.55731 / 1;
  bottom: 0px;
  flex: 0 0 auto;
  height: 100px;
  mix-blend-mode: overlay;
  overflow: visible;
  position: absolute;
  width: 890px;
  left: 0px;
}
.logOutBtnSidebar{
  margin: 50px 20px 0 60px;
  border-radius: 0;
  color: #ffffff;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  padding: 0;
  position: absolute;
  bottom: 0px;
}
.logOutBtnSidebar button{
  background: transparent;
  color: white;
  border-radius: 0;
  border: 0;
  font-weight: 600;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}
.logOutBtnSidebar img{
  margin-right: 4px;
}
.table .rt-tr-group:nth-child(odd){
  background-color: rgba(0, 0, 0, 0.03);
}
.table .rt-thead .rt-th{
  border-right: none;
}
.table .rt-tbody .rt-td{
  border-right: none;
} 
.AllContentSide{
  position: relative;
  z-index: 999;
}
.AllContentSide:before {
  position: fixed;
  content:'';
  width: 280px;
  left: 0;
  top: 0;
  height: 100vh;
  background: #191B1E;
}
.verifyBtn{
  border: 0 !important;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%) !important;
}

.table .rt-tr-group{
  background: #fff !important;
}

.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select{
  background: #E8E9EB !important;
}

.ReactTable .rt-thead{
  background: #fff !important;
}

.ReactTable{
  border: 0;
}

.table .rt-thead .rt-th{
  color: #18181B !important;
  font-weight: bold;
  padding: 15px 10px !important;
}

.table .rt-tbody .rt-td{
  color: #464F58 !important;
}

* {
  box-sizing: border-box;
  margin: 0;
}

html{
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Equinox';
  src: url('../fonts/Equinox-Bold.woff2') format('woff2'),
      url('../fonts/Equinox-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Equinox';
  src: url('../fonts/Equinox-Regular.woff2') format('woff2'),
      url('../fonts/Equinox-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


* {
    padding: 0;
    margin: 0;
}

body{
    font-family: "Montserrat", sans-serif !important;
    background: #1c2228 !important;
    position: relative;
}

body > iframe{
  display: none;
}

html {
    scroll-behavior: smooth;
    
}

body,
textarea,
input {
    padding: 0;
    margin: 0;
    line-height: 1.5;
}

a {
    display: inline-block;
}

a:hover {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
    color: #fff;
    font-weight: normal !important;
}

h1,
h2{
    line-height: 1.4 !important;
    font-weight: 400 !important;
    font-family: 'Equinox' !important;
    text-transform: uppercase;
}

h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}

h1{
  font-size: 32px !important;
}

h1 img{
  margin-right: 10px;
}

h2{
  font-size: 48px !important;
}

h3{
    font-size: 32px;
    line-height: 1.2;
}

p {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-size: 16px;
    color: #A4A7A9;
    font-weight: 300;
}

ul,
ol {
    padding: 0;
    margin: 0;
    list-style: none;
}

img{
    max-width: 100%;
}

.footer.common-btn{
  position: sticky;
  bottom: 15px;
  margin: 0 15px 15px auto  !important;
  width: 280px;
  height: 60px;
  background-size: 280px 60px;
  padding-right: 20px;
  z-index: 3;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
     margin: 0; 
}
@media screen  and (min-width: 1200px){
  .container{
    max-width: 1200px !important;
  }
}

.head span{
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  font-size: 30px;
  font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.common-btn, button.btn.common-btn, button.common-btn.btn.btn-primary{
  background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-design.png) !important;
  background-color: transparent !important;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-position: center;
  color: #fff !important;
  letter-spacing: 1px;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  width: 249px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 7px;
  box-shadow: none;
  outline: 0;
  border: 0;
  background-color: transparent !important;
  box-shadow: none !important;
}

button.btn.common-btn{
  font-weight: 400 !important;
}

.common-btn + .common-btn{
  margin-top: 20px !important;
}

.common-btn.pr{
  letter-spacing: 0.2px;
  font-size: 15px;
  padding-right: 30px;
  width: 270px;
  height: 60px;
}
.common-btn img{
  position: absolute;
  right: 10px;
}

.head{
  margin-bottom: 40px;
}

.about .row, .benefits .row, .intro .row{
  align-items: center;
}

/* Header */

header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}



/* Intro */

.intro{
  background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/banner-bg.png);
  background-size: cover;
  background-position: center top;
  position: relative;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.intro .img-wrap{
  max-width: 250px;
  margin: 0 auto;
}

.intro:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.3);
}

.intro .head span{
  font-size: 40px;
  text-transform: uppercase;
}

.intro .content{
  max-width: 624px;
}

.intro .content p{
  margin-bottom: 50px;
  color: #fff;
}


.intro video{
  width: 100%;
}

/*  About*/

.about{
  padding: 100px 0;
  position: relative;
}

.about:after{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.6);
  z-index: 1;
}

.about .head{
  max-width: 592px;
}

.about .content{
  position: relative;
  z-index: 3;
}

.about .content p{
  margin-bottom: 50px;
  color: #fff;
}

.about video{
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
}

/* Security */

.security{
  padding: 100px 0 80px;
  overflow: hidden;
  background-position: center top;
  background-repeat: no-repeat;
}

.security .col-xl-4{
  margin-bottom: 30px;
}

.security .security-card{
  padding: 30px;
  border: 1px solid #07b2d4;
  border-radius: 16px;
  height: 100%;
}

.security .security-card p:last-child{
  margin-bottom: 0;
}

.security .security-card img{
  width: 64px;
  height: 64px;
  object-fit: contain;
  margin-bottom: 20px;
}

.security .security-card h3{
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  font-size: 24px;
  font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.security .btn-wrap .common-btn{
  margin: 20px auto 0;
} 
/* Benefits */

.benefits{
  padding: 100px 0;
}
.benefits .common-btn{
 margin: 60px auto 0; 
}
.benefits.arbigon{
  background-position: center top;
  background-repeat: no-repeat;
}
.benefits.arbigon .row{
  align-items: flex-start;
}

.benefits.arbigon .img-wrap img{
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  display: block;
}
.benefits ul li{
  padding-left: 76px;
  font-size: 20px;
  margin-bottom: 30px;
  background-size: 46px;
  min-height: 46px;
  display: flex;
  align-items: center;
}

.benefits video{
  width: 100%;
}

video::-webkit-media-controls {
  display: none;
}

.token-airdrop video{
  width: 100%;
  margin: 0 auto;
}
/* RoadMap */

.roadmap{
  padding: 100px 0;
}

.phase{
  display: flex;
  align-items: flex-start;
  margin-bottom: 70px;
  position: relative;
}

.phase:before{
  position: absolute;
  content: '';
  background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/phase-stroke.png);
  width: 10px;
  height: calc(100% - 46px);
  top: 112px;
  left: 48px;
  background-repeat: no-repeat;
  background-size: contain;
}

.phase:last-child::before{
  display: none;
}

.phase .phase-number{
  width: 106px;
  height: 106px;
  margin-right: 40px;
  background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/phase.png);
  background-size: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  font-family: 'Equinox';
  font-size: 37px;
  padding-right: 5px;
}

.phase .phase-number span{
  font-size: 8px;
  transform: rotate(-90deg);
  margin-left: 4px;
  width: 2px;
  margin-top: 22px;
}

.phase .inner{
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  padding: 24px;
  width: calc(100% - 146px);
}

.phase .inner p b{
  font-weight: 500;
}

.phase .inner span{
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
}

.phase .inner h3{
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  font-size: 24px;
  font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;
}

.phase .inner p{
  margin-bottom: 5px !important;
}

.phase .inner p b{
  color: #fff;
  font-weight: 500;
}


/* Tokenomics */

.tokenomics{
    padding-bottom: 80px;
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/tokenomics-bg.png);
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
}

.tokenomics .head{
  text-align: center;
}


.tokenomics .inner .chart-wrap{
  width: 500px;
  margin: 40px auto 0;
  position: relative;
}

.tokenomics .inner .chart-wrap img{
  width: 100%;
}

.tokenomics .inner .chart-wrap .content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.tokenomics .inner .chart-wrap .content h3{
  font-size: 48px;
  font-weight: bold !important;
  margin: 0;
  line-height: 1;
}

.tokenomics .inner .chart-wrap .content span{
  font-size: 32px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 3px;
}

.tokenomics .inner .table-wrap{
  width: 100%;
}

.table-wrap table{
  width: 100%;
  border: 1px solid #07b2d4;
  border-radius: 16px;
  padding: 10px 15px;
  border-collapse: separate;
}

.tokenomics .inner .table-wrap table tr td:first-child {
  width: 43px;
}

.table-wrap table tr td{
  color: #fff;
  font-size: 14px;
  padding: 12px 10px;
}

.table-wrap table tr th{
  color: #fff;
  font-size: 15px;
  padding: 12px 10px 0;
}


/* Join US */

.join-separator{
  height: 8px;
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  width: 100%;
  margin: 0;
}

.join-us{
  margin: 5px 0;
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  padding: 80px 0;
  position: relative;
}

.join-us:before, .join-us:after{
  position: absolute;
  content: '';
  background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/join-us-pattern.png);
  background-size: cover;
  width: 296px;
  height: 296px;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

.join-us:after{
  left: auto;
  right: 5px;
  transform: translateY(-50%) rotate(180deg);
}

.join-us .content{
  text-align: center;
  max-width: 1088px;
  margin: 0 auto;
}

.join-us .content p{
  color: #fff;
  margin-top: 20px;
}


/* FAQS */

.faqs{
  padding-top: 100px;
}
.faqs .row{
  align-items: flex-end;
}
.faqs .head{
  margin-bottom: 80px;
}

.faqs .content > div{
  border: 1px solid #07b2d4;
  border-radius: 16px;
  margin-bottom: 30px;
}

.faqs .content > div h3{
  padding: 20px 65px 20px 25px;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  position: relative;
}

.faqs .content > div h3:after{
  position: absolute;
  content: '';
  right: 20px;
  top: 50%;
  background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/arrow-down.png);
  transform: translateY(-50%) rotate(-180deg);
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}

.faqs .content > div h3.show:after{
  transform: translateY(-50%) rotate(0);
}

.faqs .content > div .body{
  padding: 0 25px 5px;
}

/* buy-arbitrum */

.buy-arbitrum .head{
  text-align: center;
}

.buy-arbitrum {
  min-height: calc(100vh - 75px);
  padding: 200px 0 50px;
  background-position: center top;
  background-repeat: no-repeat;
}

.buy-arbitrum .progress-wrap{
  max-width: 380px;
  margin: 0 auto 30px;
}

.buy-arbitrum .progress{
  background: #D9D9D9;
  height: 2px;
}

.buy-arbitrum .table-wrap{
  width: 600px;
  max-width: 100%;
  margin: 50px auto 0;
  text-align: center;
}

.buy-arbitrum .head span:last-child{
  font-size: 14px;
}

.buy-arbitrum .progress .progress-bar{
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
}

.buy-arbitrum .info{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buy-arbitrum .info span{
  font-weight: 300;
  font-size: 14px;
  color: #fff;
  margin: 5px 0;
}

.buy-arbitrum .bottom.info span{
  color: #A4A7A9;
  display: inline-block;
}


.buy-arbitrum .common-btn{
  margin: 0 auto;
}

.form-group input, .form-group textarea, .input-wrap, .form-group select{
  background: #262b31 !important;
  border: 1px solid #07b2d4 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  color: #A4A7A9;
  padding: 15px;
  height: 56px;
  width: 100%;
  position: relative;
  z-index: 2;
  border-radius: 0;
  font-size: 16px;
  outline: 0;
}

.form-group.textarea:before, .form-group.textarea:after{
 display: none;
}

.form-group textarea{
  border: 1px solid #07b2d4 !important;
  border-radius: 0;
  height: 120px !important;
  outline: 0 !important;
  max-height: 120px !important;
}

.form-group .radio-wrap{
  background: #262b31;
  border: 1px solid #07b2d4;
  border-left: 0 !important;
  border-right: 0 !important;
  color: #A4A7A9;
  padding: 5px 0;
  height: 56px;
  width: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  max-width: 370px;
}
.form-group.radio{
  max-width: 370px !important;
}
.form-group .radio-wrap p{
  width: calc(50% + 25px);
  margin: 0;
  position: relative;
}
.form-group .radio-wrap input:checked + label{
  background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-design.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}
.form-group .radio-wrap p input{
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.form-group .radio-wrap p:first-child{
  margin-left: -25px;
}

.form-group .radio-wrap p:last-child{
  margin-right: -25px;
}
.form-group .radio-wrap label{
  font-size: 16px;
  color: #fff;
  padding: 0;
  margin: 0;
  display: flex;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.form-group{
  position: relative;
  max-width: calc(100% - 60px);
  margin: 0 auto 40px !important;
  width: 380px;
}

.form-group:before{
  position: absolute;
    content: '';
    width: 46px;
    height: 46px;
    border: 1px solid #07b2d4;
    border-right: 0;
    border-top: 0;
    left: 0;
    top: 5px;
    border-radius: 10px;
    transform: rotate(45deg);
    left: -20px;
    background: #262b31;
    z-index: 1;
}

.form-group:after{
  position: absolute;
    content: '';
    width: 46px;
    height: 46px;
    border: 1px solid #07b2d4;
    border-left: 0;
    border-bottom: 0;
    top: 5px;
    border-radius: 10px;
    transform: rotate(45deg);
    right: -20px;
    background: #262b31;
    z-index: 1;
}
.form-wrapper .form-group{
  margin: 0 0 20px 30px !important;
  width: 100%;
}
.form-wrapper label{
  color: #FFFFFF;
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
  margin-top: 30px;
}
.form-wrapper h2{
  font-size: 32px !important;
  margin-bottom: 10px;
}

.form-wrapper > p{
  font-size: 16px;
  margin-bottom: 12px;
}

.form-wrapper > span a{
  font-size: 14px;
 margin-bottom: 0;
 font-weight: 600 !important;
background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}
.form-wrapper{
  max-width: 500px;
}
.form-group label{
  color: #A4A7A9;
  font-size: 14px;
  padding-left: 15px;
  padding-top: 10px;
}

.filewrap{
  position: relative;
  cursor: pointer;
}

.form-group.filewrap + span{
  margin: -10px 0 20px 30px;
  display: block;
}

.filewrap .file-inner{
  background: #262b31;
  border: 1px solid #07b2d4;
  border-left: 0 !important;
  border-right: 0 !important;
  color: #A4A7A9;
  padding: 15px;
  height: 56px;
  width: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filewrap .file-inner .common-btn{
  letter-spacing: 0;
  font-size: 13px;
  width: 155px;
  margin: 0 !important;
  margin-right: -28px !important;
}
.filewrap input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
/* explore */
.explore{
  background-position: center top;
  background-repeat: no-repeat;
  min-height: calc(100vh - 75px);
  display: flex;
  flex-direction: column;
}
.explore .container{
  max-width: 100% !important;
  padding: 0;
}
.explore .common-btn{
margin: 30px auto 0;
}

.explore .card-box{
  width: 100%;
  border: 1px solid #0BC3F2;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 30px;
}
.explore .card-inner{
  padding: 10px;
  position: relative;
}
.explore .card-inner > img{
  width: 100%;
  height: 246px;
  object-fit: cover;
  border-radius: 12px;
}
.explore .card-inner h3{
  font-size: 16px;
  margin-block: 20px;
}
.explore .card-body{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-bottom: 15px;
}
.explore .card-box>img{
  max-width: 246px;
  margin-inline: auto;
  margin-bottom: 20px;
}
.explore .reaction{
  position: absolute;
  top: 25px;
  right: 25px;
  border-radius: 5px;
  padding: 2px 6px;
  background-color: #1C2228;
  display: inline-block;
}
.explore .comming-soon{
  position: absolute;
  top: 25px;
  left: 25px; 
  border-radius: 5px;
  padding: 3px 7px;
  background-color: #42C126;
  color: #ffff;
  font-size: 12px;
}

.explore .minting{
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  position: absolute;
  top: 25px;
  left: 25px; 
  border-radius: 5px;
  padding: 3px 7px;
  color: #ffff;
  font-size: 14px;
}

.explore .reaction span{
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.explore .reaction img{
  padding-right: 7px;
}
.explore .card-body img{
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.explore .card-box>span{
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
  display: block;
}
.explore .card-left{
  display: flex;
  align-items: flex-start;
}
.explore .card-left span{
  color: #A4A7A9;
  font-size: 12px;
  display: block;
}
.explore .card-left h4{
 font-size: 14px;
 margin-bottom: 0;
 font-weight: 600 !important;
background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}
.card-right-text{
  padding-left: 10px;
}
.explore .card-right span{
  background: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/card-tag-bg.png) no-repeat;
  background-size: 66px 24px;
  font-size: 14px;
  color: #FFFFFF;
  width: 66px;
  height: 24px;
  display: flex;
  align-items: center;
  background-position: center;
  justify-content: center;
}

.explore .price label{
  display: block;
  text-align: right;
  color: #A4A7A9;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 0;
}

.explore .price span{
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}

.explore .card-bottom .view-history img{
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.explore .card-bottom{
  display: flex;
  align-items: center;
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  padding: 10px;
  justify-content: space-between;
}
.explore .card-bottom .price{
  padding-right: 31px;
}
.explore .card-bottom .bold{
  font-size: 14px;
  color: #ffff;
  font-weight: bold;
}
.explore .card-bottom .current{
  display: block;
  color: #FFFFFF;
  font-size: 12px;
}
.explore .price p span:last-child{
  color: #FFFFFF;
  font-size: 12px;
}
.explore .view-history a{
  color: #FFFFFF;
  font-size: 12px;
}

.item-detail .item-views span{
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  color: #fff;
  display: inline-flex;
  align-items: center;
  gap:10px;
  border-radius: 4px;
  padding: 4px 8px;
  align-items: center;
  margin-bottom: 20px;
}

.tabs-wrapper .nav-tabs{
  border-bottom: 1px solid #07b2d4;
}

.tabs-wrapper .nav-tabs .nav-item button{
  color: #fff;
  border: 0;
}

.tabs-wrapper .nav-tabs .nav-item button.active{
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
}

.tabs-wrapper .tab-content{
  padding: 15px;
}

.price-card .form-group{
  margin: 0 0 24px 30px !important;
  max-width: ;
}
/* Mint NFT */
.mint-nft .head{
  text-align: center;
}

.mint-nft video{
  width: 100%;
}

.mint-nft p{
  text-align: center;
  max-width: 800px;
  margin: 0 auto 50px;
}

.mint-nft{
  background-position: center top;
  background-repeat: no-repeat;
}

.mint-nft .common-btn{
  margin: 30px auto 0;
}

.mint-info-wrap{
  max-width: 470px;
  margin: 0 auto 50px;
}

.mint-nft .mint-info{
  background: #262b31;
  border: 1px solid #07b2d4;
  border-left: 0 !important;
  border-right: 0 !important;
  color: #A4A7A9;
  padding: 15px 0;
  height: 56px;
  width: calc(100% - 60px);
  margin: 0 auto 40px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mint-nft .mint-info:before{
  position: absolute;
    content: '';
    width: 42px;
    height: 45px;
    border: 1px solid #07b2d4;
    border-right: 0;
    border-top: 0;
    left: 0;
    top: 5px;
    border-radius: 7px 10px 8px 10px;
    transform: rotate(45deg);
    left: -21px;
    background: #262b31;
    z-index: 1;
}

.mint-nft .mint-info:after{
  position: absolute;
    content: '';
    width: 42px;
    height: 45px;
    border: 1px solid #07b2d4;
    border-left: 0;
    border-bottom: 0;
    top: 5px;
    border-radius: 7px 10px 8px 10px;
    transform: rotate(45deg);
    right: -21px;
    background: #262b31;
    z-index: 1;
}

.mint-nft .mint-info span{
  position: relative;
  z-index: 2;
  color: #A4A7A9;
  font-weight: 500;
  font-style: italic;
  font-size: 15px;
}

.mint-nft .mint-info span:last-child{
  max-width: calc(100% - 175px);
  word-break: break-all;
}

.timer{
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.timer p span{
  display: block;
  font-size: 14px;
  margin-top: 24px;
}

.timer p{
  color: #fff;
  font-size: 32px;
  width: 80px;
  text-align: center;
  background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/timer-bg.png);
  background-size: 80px;
  background-position: center top;
  background-repeat: no-repeat;
  padding-top: 17px;
}

.apexcharts-legend{
  display: none !important;
}

/* Blogs */

.blogs{
  padding: 50px 0 70px;
}

.blogs .head{
  text-align: center;
}

.blogs article{
  border: 1px solid #07b2d4;
  padding: 16px 16px 20px;
  border-radius: 16px;
  margin-bottom: 30px;
}

.blogs article img{
  width: 100%;
  border-radius: 16px;
  margin-bottom: 20px;
}

.blogs article h3{
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}

.blogs article p{
  margin: 0;
}

.dao-wrapper .form-group{
  margin: 0 0 24px 30px !important;
  width: 100%;
  max-width: 568px;
}

.dao-wrapper p {
  margin-bottom: 24px;
}

.dao-wrapper h3 {
  margin-top: 40px;
}

.checkbox-wrap{
  position: relative;
  display: inline-block;
  margin-bottom: 24px;
}

.checkbox-wrap input{
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
opacity: 0;
}

.checkbox-wrap label{
  font-size: 18px;
  font-weight: 300;
  padding-left: 28px;
  color: #fff;
}

.checkbox-wrap label:before{
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 5px;
  left: 0;
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  top: 5px;
}
button.bid-btn.common-btn {
  background-image: none !important;
  padding: 10px 15px !important;
  width: auto !important;
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%) !important;
  height: auto;
  border-radius: 4px;
  display: inline-block;
  margin: 10px 10px 10px 0px;
  min-width: 150px;
}
button.bid-btn.common-btn img {
  display: none;
}
.price-card input[type="radio"]{
  margin-right: 5px;
}

.price-card input[type="radio"] + label{
  color: #fff;
  font-size: 16px;
  margin-right: 30px;
}
.checkbox-wrap input:checked + label:after{
  position: absolute;
    content: '';
    width: 11px;
    height: 6px;
    border: 2px solid #212529;
    border-top: 0;
    border-right: 0;
    top: 11px;
    left: 3px;
    transform: rotate(-45deg);
}

/* DAO Proposals */

.dao-wrapper {
  min-height: calc(100vh - 75px);
  padding: 200px 0 50px;
  background-position: center top;
  background-repeat: no-repeat;
}

.filter-wrap .common-btn{
  width: 295px;
  background-size: 295px 56px;
  padding-right: 30px;
}

.filter-wrap{
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.filter-wrap .form-group{
  max-width: 315px;
  margin: 0 !important;
  margin-left: 29px !important;
}

.search-wrap img{
  position: absolute;
  right: -17px;
  z-index: 2;
  top: 7px;
}

.filter-wrap .treasury-value{
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 15px;
  border: 1px solid #07b2d4;
  background: #1C2228;
  border-radius: 6px;
  margin-left: auto;
  margin-right: 25px;
}

.filter-wrap .treasury-value label, .filter-wrap .treasury-value span{
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin: 0;
}

.filter-wrap .treasury-value span{
  color: rgba(255, 255, 255, 0.4);
}

.proposals-table table{
  width: 100%;
  border-spacing: 0 12px;
  border-collapse: separate;
}

.proposals-table th{
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 12px;
  border-bottom: 1px solid #1F1F2C;
}

.proposals-table td{
  height: 75px;
  background: rgba(255,255,255,0.02);
}
.proposals-table td, .proposals-table td span{
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding: 12px;
}

.proposals-table td.expired{
  color: #A4A7A9;
}

.proposals-table td.active{
  color: #42C126;
}

.proposals-table td.vote-count{
  width: 290px;
}

.proposals-table td.vote-reject img{
  margin-right: 25px;
}

.proposals-table td.vote-reject img:last-child{
  margin-right: 0;
}

.proposals-table td span{
  color: #A4A7A9;
  padding: 0;
  display: inline-block;
  margin-right: 22px;
}

.proposals-table td span:last-child{
  margin-right: 0;
}

.proposals-table td span img{
  margin-right: 6px;
}

.proposals-table .common-btn{
  width: 156px;
  height: 42px;
  font-size: 14px;
  padding-right: 15px;
}

.proposals-table .common-btn img{
  right: 6px;
}

.proposals-table td:first-child{
  border-radius: 8px 0 0 8px;
}

.proposals-table td:last-child{
  border-radius: 0 8px 8px 0;
}
.common-modal .modal-dialog .form-wrapper label{
  margin-top: 10;
}
.common-modal.react-responsive-modal-modal, .common-modal .modal-dialog{
  width: 490px !important;
  background: #1C2228;
  border-radius: 16px;
  margin: auto;
  max-width: 92%;
}
.common-modal .modal-dialog .form-wrapper{
  padding: 0 20px 30px 20px;
}
.common-modal .modal-dialog .common-btn{
  margin: 0 auto;
}
.common-modal .modal-dialog button.close{
  position: absolute;
  right: 20px;
  top: 15px;
  opacity: 1;
}
.common-modal .modal-header{
  color: #fff;
  text-align: center;
  font-size: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.common-modal .modal-dialog .modal-content{
  background: #1C2228;
  border-radius: 16px;
}

.common-modal.react-responsive-modal-modal h2{
  text-align: center;
  color: #fff;
  font-size: 18px !important;
  text-transform: initial;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
  margin-bottom: 35px;
}

.common-modal .react-responsive-modal-closeButton{
  display: none;
}

.common-modal.react-responsive-modal-modal .modal-body{
  padding: 5px 10px 30px;
}

.common-modal.react-responsive-modal-modal .form-group{
  margin-bottom: 20px !important;
}

.common-modal.react-responsive-modal-modal .common-btn{
  margin: 0 auto;
}

button.common-close-btn{
  background: transparent;
  position: absolute;
  right: 30px;
  top: 25px;
  box-shadow: none;
  border: 0;
  z-index: 5;
}

.common-modal.react-responsive-modal-modal p{
  margin-bottom: 35px;
  text-align: center;
}

.common-modal.react-responsive-modal-modal p.note{
  text-align: center;
  margin: 0 auto;
  color: #fff;
}

/* Buy NFT */

.buy-nft, .edit-profile{
  min-height: calc(100vh - 75px);
  background-position: center top;
  background-repeat: no-repeat;
}

.buy-nft .container{
  max-width: 100% !important;
}

.buy-nft .head{
  margin-bottom: 10px;
}

.buy-nft .head h1{
  font-size: 32px !important;
}

.buy-nft .head span{
  font-size: 20px;
  margin-bottom: 10px;
  display: block;
}

.buy-nft .img-wrap{
  border: 1px solid #07b2d4;
  border-radius: 12px;
  padding: 12px;
}

.buy-nft .img-wrap img{
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.buy-nft .item-interaction{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.buy-nft .item-interaction button{
  background: transparent;
  box-shadow: none;
  border: 0;
}

.buy-nft .item-interaction .share-option{
  display: flex;
  align-items: center;
  gap: 10px;
}

.buy-nft .item-views{
  display: flex;
  align-items: center;
  gap: 15px;
}

.buy-nft .item-views span{
  border: 1px solid #07b2d4;
  border-radius: 5px;
  background: #1C2228;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  padding: 3px 5px;
}

.buy-nft p{
  margin-bottom: 30px !important;
}

.buy-nft .progressbar-head{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 2px;
}

.buy-nft .progressbar-head *{
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}

.buy-nft .progressbar-head b{
  font-size: 18px;
}

.buy-nft .progressbar-head span{
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}

.buy-nft .progress-bar{
  background: transparent;
}

.buy-nft .progress-bar{
  background: transparent;
}

.buy-nft .progress-bar >div{
  height: 3px !important;
  border-radius: 100px !important;
  background-color: #6E7380 !important;
}

.buy-nft .progress-bar >div>div{
  height: 3px !important;
  border-radius: 100px !important;
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%) !important;
}

.buy-nft .progress-bar >div>div span{
  display: none !important;
}

.progress-bar-wrap{
  margin-bottom: 30px;
}

.public-stage-wrapper h3{
  font-weight: 600 !important;
  font-size: 18px;
  margin-bottom: 15px;
}

.limit-wrap{
  border: 1px solid #07b2d4;
  border-radius: 20px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.limit-wrap .info{
width: 128px;
}

.limit-wrap .info p{
  margin: 0 !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.limit-wrap .info span{
  color: #A4A7A9;
  font-size: 14px;
  font-weight: 300;
}

.limit-wrap .form-group{
  width: 170px;
  margin: 0 auto !important;
  max-width: calc(100% - 330px);
}

.limit-wrap .form-group input{
  height: 42px;
  padding: 0 20px;
  text-align: center;
  outline: 0;
}

.limit-wrap .form-group:before{
    width: 36px;
    height: 35px;
    top: 3.5px;
    left: -16px;
}

.limit-wrap .form-group:after{
    width: 36px;
    height: 35px;
    top: 3.5px;
    right: -16px;
}

.limit-wrap .form-group img{
  position: absolute;
  z-index: 3;
  top: 7px;
}

.limit-wrap .form-group img.increment{
  left: -12px;
}

.limit-wrap .form-group img.decrement{
  right: -12px;
}

.limit-wrap .common-btn{
  width: 150px;
  height: 40px;
  background-size: 150px 40px;
}

.ecosystem .head{
  text-align: center;
}

.ecosystem img{
  width: 100%;
}

.edit-profile{
  text-align: center;
}

.edit-profile p{
  max-width: 800px;
  margin: 0 auto 40px;
}

.edit-profile .form-group{
  margin-bottom: 20px !important;
}

.edit-profile .img-wrap{
  width: 278px;
  height: 278px;
  border-radius: 12px;
  padding: 12px;
  border: 1px solid #07b2d4;
  position: relative;
  margin: 0 auto 30px;
}

.edit-profile .img-wrap img{
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.edit-profile .img-wrap img.edit-icon{
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 20px;
  right: 20px;
  border-radius: 0;
}

.edit-profile .img-wrap input{
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.explore.pr{
  background-image: none;
  padding: 0 0 100px;
}
.profile .inner-wrap{
  border: 1px solid #07b2d4;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 30px;
}
.profile .inner{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 30px;
}

.profile .inner .img-wrap{
  width: 192px;
  height: 192px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.profile .inner .img-wrap img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile .inner .img-wrap input{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.profile .inner .img-wrap img.edit-icon{
  width: 30px;
  height: 30px;
  right: 10px;
  bottom: 10px;
  position: absolute;
}
.profile .inner .content{
  width: calc(100% - 192px);
  padding-left: 30px;
}

.profile .inner .upper{
  display: flex;
  align-items: flex-start;
}

.profile .inner .upper h3{
  font-weight: 600 !important;
  font-size: 24px;
  margin-bottom: 25px !important;
}

.profile .inner .upper p{
  margin-bottom: 15px;
}

.profile .inner .upper > div{
  width: calc(100% - 222px);
  padding-right: 10px;
}

.profile .inner .upper ul{
  list-style: none;
  display: flex;
  gap: 10px;
}

.profile .inner .upper ul li{
  padding: 0;
  margin: 0;
  background-image: none;
}

.profile .inner .bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile .inner .bottom .address span{
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  font-size: 14px;
  font-weight: 500;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
}

.profile .inner .bottom .address{
  display: flex;
  align-items: flex-end;
}

.profile .inner .bottom .address img{
  margin-left: 5px;
}

.profile .inner .common-btn{
  width: 204px;
  height: 46px;
  padding-right: 20px;
}

.profile .bottom-info{
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  padding: 10px 25px;
  min-height: 40px;
}

.collections-info{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
}

.collections-info p{
  background: #1C2228;
  padding: 7px 10px;
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid #025161;
}

.collections-info p span{
  color: #fff;
}
.profile .bottom-info .info{
  text-align: center;
}

.profile .bottom-info span{
  display: block;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
}

.profile .bottom-info label{
  display: block;
  color: #262E36;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.filters-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.filters-wrap select{
  color: #EBEBEB;
  border: 1px solid #07b2d4;
  border-radius: 6px;
  background: #1C2228;
  padding: 10px;
  width: 110px;
  outline: 0;
}

.filters-wrap .form-group{
  width: calc(100% - 180px);
  margin: 0 0 0 30px !important;
}

.percentage-btn{
  display: flex;
  justify-content: space-between;
  gap: 2%;
}

.percentage-btn .common-btn{
  width: 22%;
  margin: 0 !important;
}

.percentage-btn .common-btn{
  width: 22%;
  margin: 0 !important;
  background-size: 120px 40px;
}


/* Game */
.game{
  padding: 120px 0;
  position: relative;
  overflow: hidden;
}

.game:after{
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(0,0,0,0.8);
}

.game video{
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  object-fit: cover;
}

.game .inner{
  border: 1px solid #07b2d4;
  border-radius: 16px;
  padding: 50px 20px;
  min-height: 564px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.game .inner p{
  text-align: center;
}

.game .inner h2{
  margin-bottom: 50px;
}

/* Create */

.create-item-wrapper .container{
  max-width: 100% !important;
  padding: 0;
}
.create-item-wrapper .form-wrapper{
  max-width: 100%;
}
.collection-box {
  border: 1px solid #07b2d4;
  border-radius: 12px;
  padding: 12px 12px 25px;
}
.collection-box img{
  width: 100%;
  border-radius: 12px;
  margin-bottom: 15px;
}

.collection-content-head h3{
  margin-bottom: 12px;
}

.collection-content-head .common-btn{
  width: 90px;
  height: 29px;
  background-size: 90px 30px;
}

.collection-box-wrap h3{
  color: #fff;
  margin-bottom: 20px;
}

.choose {
  margin-bottom: 40px;
}

.choose .box{
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 16px;
  background: rgba(255,255,255,0.02);
  width: 48.5%;
}

.choose .box.active{
  border: 1px solid #07b2d4;
}

.choose .box img{
  width: 120px;
}

.choose .box .info{
  width: calc(100% - 120px);
  padding-left: 20px;
}

.choose{
  display: flex;
  justify-content: space-between;
}

/* Swap */

.swap .form-group{
  margin: 0 auto 20px !important;
  width: 500px;
}

.swap .inner{
  max-width: 560px;
  margin: 0 auto;
}

.swap .swap-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
}

.swap .swap-head p{ 
  margin: 0 !important;
  font-size: 16px !important;
  cursor: pointer;
}

.swap .swap-head h3{ 
  font-size: 24px !important;
  font-weight: 600 !important;
}

.form-group .input-wrap{
  padding: 10px 15px;
  display: flex;
}

.form-group .input-wrap input{
  padding: 0;
  border: 0;
  background: transparent;
  height: 100%;
  width: calc(100% - 170px);
  padding-right: 10px;
}

.swap-icon{
  margin: 0 auto 20px;
  display: block;
  cursor: pointer;
}

.swap .input-wrap span{
  width: 170px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  border-left: 1px solid #07b2d4;
  line-height: 1;
  cursor: pointer;
}

.swap .input-wrap span img{
  margin-right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.select-token .btn-wrap{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
}

.form-group.search input{
  /* background-image: url(../images/search-normal.svg); */
  background-size: 24px !important;
  background-position: 0 50%;
  background-size: contain;
  background-repeat: no-repeat;
  padding-left: 35px;
}

.common-modal.react-responsive-modal-modal.select-token .btn-wrap .common-btn{
  margin: 0 !important;
  width: 130px;
  height: 40px;
  background-size: 130px 40px;
}

.common-modal.react-responsive-modal-modal.select-token .btn-wrap .common-btn img{
  position: static;
  margin-right: 10px;
}

.common-modal label{
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
}

.common-modal.react-responsive-modal-modal.swap-modals h2{
  text-align: left;
  font-family: 'Equinox' !important;
  font-size: 32px !important;
}

.common-modal.react-responsive-modal-modal.swap-modals{
  width: 640px !important;
}

.common-modal.react-responsive-modal-modal.swap-modals .form-group{
  width: 100%;
}

.explore.swap .inner > .common-btn {
  margin: 50px auto 0;
}

.dropdown-wrapper{
  position: absolute;
  max-width: 360px;
  z-index: 10;
  background: #1C2228;
  border-radius: 15px;
  padding: 25px 20px;
  right: 0;
  top: 100%;
}

.dropdown-wrapper .close-btn{
background: transparent;
border: 0;
right: 20px;
top: 20px;
position: absolute;
}

.dropdown-wrapper ul li{
  background-image: none;
  padding: 0;
  font-style: normal;
}

.explore .dropdown-wrapper .common-btn{
  margin: 0 !important;
  width: 140px;
  background-size: 140px 35px;
  padding: 0;
  height: 100%;
  position: relative;
  right: -25px;
}

.swap .dropdown-wrapper .input-wrap input{
  width: calc(100% - 140px);
}


.swap .dropdown-wrapper .common-btn img {
  position: absolute;
  right: 3px;
  width: 28px;
  top: 3px;
}

.common-modal .form-group .input-wrap input{
  width: 40%;
}

.common-modal .form-group .input-wrap span{
  border-left: 1px solid #07b2d4;
  line-height: 2;
  padding-left: 10px;
  width: 60%;
}

.form-group-wrapper{
  display: flex;
  justify-content: space-between;
}

.form-group-wrapper > div{
  width: 49%;
}

.whitelist{
  min-height: calc(100vh - 80px);
}

.common-modal span.tier{
  display: block;
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  font-size: 16px;
  font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: -10px 0 20px;
}


.common-modal .form-group.inc-dec-wrap input{
  padding-right: 78px;
}

.common-modal .form-group.inc-dec-wrap img{
  position: absolute;
  top: 7px;
  z-index: 3;
}

.common-modal .form-group.inc-dec-wrap img.increment{
  right: -16px;
}

.common-modal .form-group.inc-dec-wrap img.decrement{
  right: 30px;
}

.form-group.liquidity-amount span{
  position: absolute;
  color: #fff;
  font-size: 16px;
  right: 0;
  z-index: 4;
  top: 17px;
}
/* Footer */

/* Querry */
@media screen and (min-width:1600px){
.explore .col-md-6.col-lg-4{
  flex: 0 0 25%;
  max-width: 25%;
}
}
@media screen and (max-width:1199px){
  .tokenomics .inner .chart-wrap .content h3{
    font-size: 36px;
  }
  .tokenomics .inner .chart-wrap .content span{
    font-size: 28px;
  }
  .navbar ul{
    width: calc(50% - 87px);
  }

  h1 {
    font-size: 32px !important;
  }

  .intro .content,
  .about .content,
  .img-wrap{
    margin-bottom: 30px;
  }

  .faqs .img-wrap{
    margin-bottom: 0;
  }

  h2 {
    font-size: 40px !important;
  }
  .tokenomics .inner .chart-wrap{
    width: 40%;
  }
  .tokenomics .inner .table-wrap {
    width: 55%;
  }
  .tokenomics .inner .table-wrap table tr th {
    padding: 12px 6px 0;
  }
}

@media screen and (max-width:991px){
  .mint-nft, .explore, .buy-arbitrum{
    padding-top: 260px;
  }

  .intro{
    padding: 260px 0 20px;
    text-align: center;
  }
  .intro .common-btn{
    margin: 0 auto;
  }

  .navbar{
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }

  .navbar .logo-wrap{
    order: 1;
    width: 100%;
    text-align: center;
  }

  .navbar ul{
    order: 2;
    width: calc(50% - 20px);
  }

  .about,
  .benefits,
  .roadmap {
    padding: 50px 0;
  }
  .about .content p {
    margin-bottom: 35px;
  }
  .head {
    margin-bottom: 25px;
  }
  .faqs {
    padding-top: 50px;
  }
  .faqs .head {
    margin-bottom: 40px;
  }
  .join-us:before,
  .join-us:after {
    display: none;
  }
  .tokenomics .inner {
    flex-direction: column;
    row-gap: 35px;
}
.tokenomics .inner .chart-wrap,
.tokenomics .inner .table-wrap {
    width: 100%;
  }
}

@media screen and (max-width:767px){

  .intro:before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
  }
  
  .mint-nft, .explore, .buy-arbitrum{
    padding-top: 280px;
  }

  .navbar ul li a{
    font-size: 15px;
  }

  .intro{
    padding-top: 280px;
    background-position: 83% top;
  }

  .navbar{
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .navbar ul{
    order: 2;
    width: 100%;
    gap: 30px;
    justify-content: center;
    margin: 0;
  }

  .explore .card-inner > img{
    height: auto;
  }
  .security{
    padding: 80px 0;
  }

  .security .head{
    text-align: center;
  }

  .benefits ul li {
    padding-left: 60px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .phase .phase-number {
    width: 85px;
    height: 85px;
    margin-right: 15px;
    background-size: 85px;
    font-size: 30px;
  }
  .phase:before {
    width: 2px;
    height: calc(100% - 46px);
    top: 100px;
    left: 42px;
    background-image: none;
    background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  }
  .phase .inner {
    width: calc(100% - 100px);
    padding: 15px;
  }
  .phase .inner h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .join-us {
    padding: 50px 0;
  }
  .tokenomics .inner .table-wrap table tr td {
    padding: 12px 3px;
  }
  .tokenomics .inner .chart-wrap .content h3 {
    font-size: 38px;
  }
  .tokenomics .inner .chart-wrap .content span {
    font-size: 32px;
 }
  footer .inner{
    flex-direction: column;
    row-gap: 15px;
  }
  footer .inner ul{
    gap: 20px;
  }
  footer .copyright p {
    text-align: center;
  }
  .faqs .row{
    flex-direction: column-reverse;
  }
}

@media screen and (max-width:575px){
  footer .copyright p{
    font-size: 12px;
  }
  .tokenomics .inner .table-wrap table {
    width: 575px;
 }
  .tokenomics .inner .table-wrap {
    width: 100%;
    overflow: auto;
 }
}